<template>
  <section class="poll__answers--container">
    <div class="poll__answers--content custom__scroll">
      <div v-for="(option, idx) in questionSelected.options" :key="option._id" class="poll__answers--answer">
        <input type="checkbox" :id="'poll__option--checkbox-' + idx" @change="vote($event, option)" />
        <label class="poll__title--container" :for="'poll__option--checkbox-' + idx">
          <span class="poll__answer--title">{{ option.text }}</span>
        </label>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ["deleteCheck", "answerSelectedToShow", "questionSelected", "hasVotes"],
  methods: {
    selectAnswer() {},
    vote(evt, option) {
      const isChecked = evt.target.checked;
      if (isChecked) {
        this.$emit("vote", {
          questionId: this.questionSelected._id,
          optionId: option._id,
        });
      } else {
        this.$emit("deleteCheck", {
          option: option._id,
          question: this.questionSelected._id,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.poll {
  &__answers--container {
    width: 95vw;
    max-height: 75%;
  }
  &__answers--content {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__answers--answer {
    display: flex;
    align-items: center;
  }
  &__title--container {
    padding: 15px;
    border-radius: 30px;
    width: 100%;
    margin-left: 10px;
    background: rgba(211, 211, 211, 0.7);
    border: 3px solid #fff;
    transition: background 0.3s;
    cursor: pointer;
    text-align: center;
    &:hover {
      background: rgba(255, 255, 255, 0.7);
    }
  }
  input[type="checkbox"] {
    width: 25px;
    height: 25px;
    background: rgba(211, 211, 211, 0.7);
    border-radius: 50%;
    vertical-align: middle;
    border: 3px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
  }
  input[type="checkbox"]:checked {
    background: rgba(211, 211, 211, 0.7);
  }
  @include desktopWidth {
    &__answers--container {
      width: 70vw;
      max-width: 90vw;
      max-height: 75%;
    }
    &__answers--content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      gap: 20px;
    }
    &__answers--answer {
      display: flex;
      align-items: center;
    }
    &__title--container {
      padding: 15px;
      border-radius: 30px;
      width: 100%;
      margin-left: 10px;
      background: rgba(211, 211, 211, 0.7);
      border: 3px solid #fff;
      transition: background 0.3s;
      cursor: pointer;
      text-align: center;
      &:hover {
        background: rgba(255, 255, 255, 0.7);
      }
    }
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      background: rgba(211, 211, 211, 0.7);
      border-radius: 50%;
      vertical-align: middle;
      border: 2px solid #ddd;
      appearance: none;
      -webkit-appearance: none;
      outline: none;
      cursor: pointer;
    }
    input[type="checkbox"]:checked {
      background: #fff;
    }
  }
}
</style>
